//
// Thumbnails
// --------------------------------------------------


// Mixin and adjust the regular image class
.thumbnail {
  display: block;
  padding: $thumbnail-padding;
  margin-bottom: $line-height-computed;
  line-height: $line-height-base;
  background-color: #fff;
  border: 1px solid $thumbnail-border;
  border-radius: $thumbnail-border-radius;
  @include transition(border .2s ease-in-out);

  span.update-image {
    display: none;
    position: absolute;
    right: 19px;
  }

  button.remove-image {
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  &:hover span.update-image,
  &:hover button.remove-image {
    display: inline-block;
  }

  > img,
  a > img {
    @include img-responsive;
    margin-left: auto;
    margin-right: auto;
  }

  // [converter] extracted a&:hover, a&:focus, a&.active to a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active

  // Image captions
  .caption {
    padding: $thumbnail-caption-padding;
    color: $thumbnail-caption-color;
  }
}

.add-image,
.thumbnail-placeholder {
    text-align: center;
    display:block;
    position:relative;

    border-width: 2px;
    border-style: dashed;
    .placeholder-content {
      background-color:$gray-x-light;
      display:block;
      padding:50px 0;
      .fa {
        color:$gray-light;
        font-size:42px;
      }
    }
    span.update-image {
        right: 4px;
    }
    button.remove-image {
        left: 4px;
    }
}

// Add a hover state for linked versions only
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: darken($thumbnail-border,25%);
  cursor:pointer;
}
