//
// Wells
// --------------------------------------------------


// Base class
.well {
  min-height: 20px;
  padding:15px 20px;
  background-color: $gray-x-light;
  @include box-shadow(inset 0 1px 0 rgba(0,0,0,.1));
  blockquote {
    border-color: $gray-light;
    border-color: rgba(0,0,0,.15);
  }
  hr {
    margin:5px 0;
  }
}

// Sizes
.well-lg {
  padding: 24px;
  border-radius: $border-radius-large;
}
.well-sm {
  padding: 9px;
  border-radius: $border-radius-small;
}
