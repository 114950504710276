//
// Breadcrumbs
// --------------------------------------------------
.secondary-nav {
    border-bottom: 1px solid $gray-light;

    .breadcrumb {
        margin-bottom: 0;
        border-bottom: 0;
    }
}

.breadcrumb {
    margin-bottom: $line-height-computed;
    padding: 15px 0;

    border-bottom: 1px solid $gray-light;
    background-color: transparent;

    list-style: none;
    > li {
        display: inline-block;
        + li:before {
            padding: 0 8px 0 5px;

            color: $breadcrumb-color;

            content: '#{$breadcrumb-separator}';
        }
    }
    > .active {
        color: $breadcrumb-active-color;
    }
    &.nav-split > li + li:before {
        content: '#{$navsplit-separator}';
    }
}



.breadcrumb-actions .btn-primary {
    float: right;
    margin-top: 8px;
}
