// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  @include transition(all .1s ease-in);

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 5%);
    border-color: darken($border, 10%);
    &.btn-default {
      color: darken($color,20%);
      background-color: darken($background, 2%);
    }
  }


  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: darken($background, 10%);
    background-image: none;
    &.btn-default {
      background-color: darken($background, 5%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $background;
          border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

//
// Buttons
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  background-color: $gray-x-light;
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $border-radius-small);
  @include user-select(none);

  &.btn-circle {
    border-radius: 50%;
    padding-left:0px;
    padding-right:0px;
    min-width:34px;
  }

  &.btn-dashed {
	border-style: dashed;
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: 0;
      // @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    outline: 0;
    color: $btn-default-color;
    text-decoration: none;
    @include box-shadow(0 1px 2px rgba(0,0,0,.1));
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 1px 0 rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    pointer-events: none; // Future-proof disabling of clicks
    @include opacity(.50);
    @include box-shadow(none);
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include box-shadow(inset 0 1px 0 rgba(255,255,255,.5));
  text-shadow:0 1px #fff;
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}
.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
// Success appears as green
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

.btn-dashed {
  font-weight:600;
	@include button-variant($link-color, transparent, $btn-primary-border);
}

// Social media buttons
// --------------------------------------------------

// Twitter
.btn-twitter {
  @include button-variant($btn-twitter-color, $btn-twitter-bg, $btn-twitter-border);
}
// Facebook
.btn-facebook {
  @include button-variant($btn-facebook-color, $btn-facebook-bg, $btn-facebook-border);
}
// Linkedin
.btn-linkedin {
  @include button-variant($btn-linkedin-color, $btn-linkedin-bg, $btn-linkedin-border);
}
// Pinterest
.btn-pinterest {
  @include button-variant($btn-pinterest-color, $btn-pinterest-bg, $btn-pinterest-border);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    @include box-shadow(none);
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
    background-color: transparent;
    @include box-shadow(none);
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
      @include box-shadow(none);
    }
  }
}

.btn-switch {
  color: $gray-demi-light;
  font-weight: normal;
  padding: 0;
  margin-bottom: -0.9em;

  .fa-toggle {
    font-size: 2em;
    &:before {
      content: "\f204";
    }
  }
  .fa-spinner {
    display: none;
    font-size: 1.5em;
    width: 1.5em;
  }
  &.active {
    color: $btn-success-bg;
    .fa-toggle:before {
      content: "\f205";
    }
    &:hover,
    &:focus {
      color: darken($btn-success-bg, 10%);
    }
  }

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    @include box-shadow(none);
  }
  &:hover,
  &:focus {
    color: darken($gray-demi-light, 10%);
    text-decoration: none;
    background-color: transparent;
    @include box-shadow(none);
  }
  &[disabled],
  &[disabled].active,
  fieldset[disabled] &,
  fieldset[disabled] &.active {
    &.processing,
    &.processing:hover,
    &.processing:focus {
      color: $gray-demi-light;
      .fa-toggle {
        display: none;
      }
      .fa-spinner {
        display: inline-block;
      }
    }
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
      @include box-shadow(none);
    }
  }
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}
.btn-xs {
  @include button-size(1px, 5px, $font-size-small, $line-height-small, $border-radius-small);
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
