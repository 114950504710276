//
// Forms
// --------------------------------------------------

.form-group {
    margin-bottom: $form-group-margin-bottom;
    &.form-group-spacing {
        margin-top: $form-group-margin-bottom;
    }
}

.form-control {
    resize: vertical;
}

.list-group {
    .form-control.form-addition {
        resize: vertical;
    }
    select[multiple].select2-hidden-accessible,
    select[size].select2-hidden-accessible {
        height: 1px;
    }
    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid $gray-light;
        }
    }
    .select2-container--default {
        .select2-selection--multiple {
            border: 1px solid $gray-light;
            border-radius: 3px;
            background-color: transparent;
        }
        .select2-results__option--highlighted[aria-selected] {
            background-color: $blue;
        }
    }
    .select2-container--default.select2-container--focus {
        .select2-selection--multiple {
            border: solid $blue 1px;
            background-color: #fff;
        }
    }
}
