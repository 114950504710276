//
// Close icons
// --------------------------------------------------


.close {
    font-size: 18px;
    font-weight: $close-font-weight;
    line-height: 1;
    color: $close-color;
    text-shadow: $close-text-shadow;

    @include opacity(0.2);
    &:hover,
    &:focus {
        color: $close-color;
        text-decoration: none;

        cursor: pointer;

        @include opacity(0.5);
    }
    // [converter] extracted button& to button.close
}

.fa.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2; // Ensure icon is above input groups

    display: block;
    width: $input-height-base;
    height: $input-height-base;

    line-height: $input-height-base;
    text-align: center;
    pointer-events: none;
}

.fa-squared {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-right: 5px;

    font-size: 20px;
    line-height: 26px !important;
    color: #fff;
    text-align: center;
    vertical-align: text-top;

    border-radius: $border-radius-small;
    background-color: #676767;
    &.facebook {
        background-color: $facebook-primary;
        padding-top: 1px;
    }
    &.twitter {
        background-color: $twitter-primary;
    }
    &.linkedin {
        background-color: $linkedin-primary;
    }
}

.bullet-green {
    background-color: #5cb85c;
    width: 10px;
    height: 10px;
    border-radius: 25px;
}

.bullet-red {
    background-color: #c00;
    width: 10px;
    height: 10px;
    border-radius: 25px;
}
