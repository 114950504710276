@mixin font-face($font-family, $file-path, $font-weight, $font-style, $font-stretch) {
    @font-face {
        font-family: $font-family;
        font-weight: $font-weight;
        font-style: $font-style;
        font-stretch: $font-stretch;
        src: url('../fonts/#{$file-path}.eot');
        src: url('../fonts/#{$file-path}.eot?#iefix') format('embedded-opentype'),
        url('../fonts/#{$file-path}.woff') format('woff'),
        url('../fonts/#{$file-path}.ttf') format('truetype'),
        url('../fonts/#{$file-path}.svg##{$font-family}') format('svg');
    }
    // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        @font-face {
            font-family: $font-family;
            src: url('../fonts/#{$file-path}.svg##{$font-family}') format('svg');
        }
    }
}

@mixin no-svg-font-face($font-family, $file-path, $font-weight, $font-style, $font-stretch) {
    @font-face {
        font-family: $font-family;
        font-weight: $font-weight;
        font-style: $font-style;
        font-stretch: $font-stretch;
        src: url('../fonts/#{$file-path}.eot');
        src: url('../fonts/#{$file-path}.eot?#iefix') format('embedded-opentype'),
        url('../fonts/#{$file-path}.woff') format('woff'),
        url('../fonts/#{$file-path}.ttf') format('truetype');
    }
    // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        @font-face {
            font-family: $font-family;
            src: url('../fonts/#{$file-path}.svg##{$font-family}') format('svg');
        }
    }
}

/*=============================================================================
OpenSans
=============================================================================*/

/* OpenSans Regular */
@include font-face(Open Sans, 'OpenSans/OpenSans', 400, normal, normal);

/* OpenSans Regular Italic */
@include font-face(Open Sans, 'OpenSans/OpenSans-Italic', 400, italic, normal);

/* OpenSans Semi Bold */
@include font-face(Open Sans, 'OpenSans/OpenSans-Semibold', 600, normal, normal);

/* OpenSans Semi Bold Italic */
@include font-face(Open Sans, 'OpenSans/OpenSans-SemiboldItalic', 600, italic, normal);

/* OpenSans Bold */
@include font-face(Open Sans, 'OpenSans/OpenSans-Bold', 700, normal, normal);

/* OpenSans Bold Italic */
@include font-face(Open Sans, 'OpenSans/OpenSans-BoldItalic', 700, italic, normal);

/*=============================================================================
Open Sans Condensed
=============================================================================*/

/* OpenSans Bold Italic */
@include font-face(Open Sans Condensed, 'OpenSansCondensed/OpenSans-CondensedLight', 400, normal, condensed);

/* OpenSans Bold Italic */
@include font-face(Open Sans Condensed, 'OpenSansCondensed/OpenSans-CondensedBold', 700, normal, condensed);

/*=============================================================================
Inconsolata (Monospaced font)
=============================================================================*/

/* Inconsolata Regular */
@include font-face(Inconsolata, 'Inconsolata/Inconsolata', 400, normal, normal);

/* Inconsolata Bold */
@include font-face(Inconsolata, 'Inconsolata/Inconsolata-Bold', 700, normal, normal);

/*=============================================================================
Droid Sans (Serif font)
=============================================================================*/

/* Merriweather */
@include no-svg-font-face('Merriweather', 'Merriweather/Merriweather-Light', 200, normal, normal);

/* Merriweather */
@include no-svg-font-face('Merriweather', 'Merriweather/Merriweather-LightItalic', 200, italic, normal);

/* Merriweather */
@include no-svg-font-face('Merriweather', 'Merriweather/Merriweather-Regular', 400, normal, normal);

/* Merriweather */
@include no-svg-font-face('Merriweather', 'Merriweather/Merriweather-Italic', 400, italic, normal);

/* Merriweather */
@include no-svg-font-face('Merriweather', 'Merriweather/Merriweather-Bold', 700, normal, normal);

/* Merriweather */
@include no-svg-font-face('Merriweather', 'Merriweather/Merriweather-BoldItalic', 700, italic, normal);

/*=============================================================================
Icon fonts
=============================================================================*/

/* Fontawesome */
@include font-face('FontAwesome', 'Fontawesome/fontawesome-webfont', normal, normal, normal);

/* Ionicons */
@include font-face('Ionicons', 'Ionicons/ionicons', normal, normal, normal);

