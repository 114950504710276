/* This is a direct copy of the styles for the message box from
 * common.css in the ColdFusion's codebase. This is a temporary solution,
 * for the Google SSO pysettings page while we wait for the new message
 * component to be ready in the design system. Once that is ready and
 * used by the Google SSO settings page, this file (and the import from
 * base.scss) can be removed. */

.update {
    position: fixed;
    top: -50px;
    right: 0;
    left: 0;
    z-index: 99999;

    height: 50px;
    overflow: hidden;

    line-height: 50px;

    background: rgba(30, 30, 30, 0.9); /* Old browsers */
    -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);

    -webkit-transition: top 0.2s, height 0.2s; /* Safari and Chrome */
    -moz-transition: top 0.2s, height 0.2s; /* Firefox 4 */
    -o-transition: top 0.2s, height 0.2s; /* Opera */
    transition: top 0.2s, height 0.2s;
}

.update.show {
    top: 0;
}

.update.info {
    background: #1ca0de;
}

.update.warning {
    background: rgba(120, 54, 54, 0.9);
}

.update.extended {
    height: 90px;
}

.update {
    padding: 0 5%;
}

.loader {
    display: block;
    float: left;
    width: 150px;
    height: 12px;
    margin: 0;
    margin-top: 17px;
    padding: 0;
    padding-right: 4px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -ms-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -o-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);

    list-style: none;
}
.loader div {
    position: relative;
    top: 2px;
    left: 2px;

    display: block;
    width: 100%;
    height: 8px;
    overflow: hidden;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background: rgb(45, 134, 184);
    -webkit-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.4);
    -moz-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.4);
    -ms-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.4);
    -o-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.4);
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.4);
}
.loader div > span {
    display: block;
    height: 8px;
    overflow: hidden;

    background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0.2)),
    color-stop(100%, rgba(0, 0, 0, 0.2))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
    ); /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
    background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.2)),
    to(rgba(0, 0, 0, 0.2))
    );
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
    ); /* W3C */

    -ms-filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#33ffffff', endColorstr='#33000000',GradientType=0 )"; /* IE6-9 */
}

.loader div > span:after,
.loader div > span > span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    left: 8px;
    z-index: 1;

    overflow: hidden;

    background-image: -webkit-gradient(
    linear,
    0 0,
    100% 100%,
    color-stop(0.25, rgba(255, 255, 255, 0.2)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, transparent),
    to(transparent)
    );
    background-image: -webkit-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
    );
    background-image: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
    );
    background-image: -o-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
    );
    -moz-background-size: 20px 20px;
    background-size: 20px 20px;

    content: '';
    -webkit-animation: move 2s linear infinite;
}

.loader div > span:after {
    display: none;
    overflow: hidden;
}

@-webkit-keyframes move {
    0% {
    background-position: 0 0;
    }
    100% {
    background-position: 20px 20px;
    }
}

.update .notification {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    padding: 0 25px;

    text-align: center;
}

.update .notification h1 {
    margin: 0;

    font-size: 14px;
    font-weight: normal;
    line-height: 50px;
    color: #fff;
}

.update .notification h1 i {
    margin-top: -2px;
}

.update .notification span.extended {
    font-size: 15px;
    color: #f2f2f2;
}

.update a.cancel {
    position: absolute;
    top: 0;
    right: 0;

    width: 50px;
    height: 50px;
    padding: 0;

    line-height: 50px;
    color: #fff;
    text-align: center;

    background-color: rgba(0, 0, 0, 0.05);

    cursor: pointer;
}

.update a.cancel:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
