// Base class
.filters {
    border: 1px solid $gray-light;
    border-radius: $border-radius-small;
    background-color: #fff;
}

.filters-header {
    padding: $padding-base-vertical $padding-base-horizontal;

    border-bottom: 1px solid #ccc;
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;
    background-color: #fff;
}

.filters-body {
    padding: $padding-large-vertical $padding-large-horizontal;
}

ul.filters-list {
    margin: 0;
    padding-left: 0;

    list-style: none;
}

li.filter {
    float: left;
}

a.filter-btn {
    position: relative;

    display: block;
    height: 30px;
    padding: 0 15px;

    line-height: 30px;
}

.open > a.filter-btn {
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.2);
}
.open > a.filter-btn:after {
    position: absolute;
    right: 0;
    bottom: -3px;
    left: 0;
    z-index: 1001;

    display: block;
    height: 6px;

    background-color: #fff;

    content: '';
}

.filter-menu {
    top: auto;
    left: auto;

    width: auto;
    padding: 15px;

    border: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.2);
}

.filter-level {
    position: relative;

    float: left;
    width: 280px;
    padding: $padding-base-vertical $padding-base-horizontal;

    background-color: #fff;
    > h4 {
        margin-top: 0;
    }
}

.filter-option {
    display: block;
    padding: $padding-base-vertical 0;

    border-bottom: 1px solid $gray-light;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.selected .filter-option {
    font-weight: 600;
}


.filter-options > li:last-child .filter-option {
    border-bottom: 0;
}

.filter-counter {
    float: right;
}

.selected .filter-counter {
    background-color: $brand-success;
}

.selected .filter-counter:before {
    display: inline-block;
    margin-left: -1px;
    padding-right: 5px;

    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    font-size: 14px;
    line-height: 1;
    color: #fff;

    content: '\f058';

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: auto;
}

.filter-scroll {
    max-height: 360px;
    overflow-y: scroll;
}

.filter-scroll-helper {
    margin-right: -27px;
    margin-bottom: -21px;
    margin-left: -27px;
    padding: 10px 0;
    overflow: hidden;

    text-align: center;

    border-top: 1px solid $gray-light;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.05);
}

.btn-filter {
    float: right;
    margin: 0.8em 0;
}

.label-filter {
    display: inline-block;
    max-width: 230px;
    overflow: hidden;

    text-overflow: ellipsis;
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.badge-default {
    @include label-variant($label-default-bg);
}

.badge-primary {
    @include label-variant($label-primary-bg);
}

.badge-success {
    @include label-variant($label-success-bg);
}

.badge-info {
    @include label-variant($label-info-bg);
}

.badge-warning {
    @include label-variant($label-warning-bg);
}

.badge-danger {
    @include label-variant($label-danger-bg);
}
