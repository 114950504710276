//
// DataTimePicker
// --------------------------------------------------
.bootstrap-datetimepicker-widget {
    transform: translate(20px,0);
    .datepicker,
    .timepicker,
    .btn {
        background-color: #fff;
        box-shadow: none;
    }
    a,
    a:hover {
        .fa-chevron-up,
        .fa-chevron-down {
            color: #000;
        }
    }
    .datepicker table tr td span.active.active,
    .datepicker table tr td.active.active {
        color: #fff;

        background-color: $blue;
    }
    .fa {
        margin: 0;
    }
    .datepicker table tr td.old,
    .datepicker table tr td.new {
        color: $gray-base;
    }
    a[data-action='togglePicker'] {
        color: $blue;
    }
}

.datepicker-inline {
    width: 100% !important;
}

.datepicker table tr {
    td {
        position: relative;

        -webkit-border-radius: 0 !important;
           -moz-border-radius: 0 !important;
                border-radius: 0 !important;
        &.date-in-range {
            &.active,
            &.active.active {
                color: $gray;

                background-color: $gray-x-light;
            }
        }
        &.old.day.disabled,
        &.new.day.disabled,
        &.old.day.disabled:hover,
        &.new.day.disabled:hover {
            color: $gray-light;
        }
        &.old,
        &.new {
            background-color: $gray-x-light;
        }
        &.today,
        &.today.disabled {
            font-weight: bold;

            border: 0 !important;
            background-color: transparent !important;
        }
        &.today:hover,
        &.today.disabled:hover {
            background-color: $gray-x-light !important;
        }
        &.today:after {
            position: absolute;
            top: 0;
            left: 0;
            height: 40px!important;

            width: 100%;
            height: 100%;

            border: 1px solid $gray-light;

            content: '';
        }
        &.today.active:after,
        &.today.range:after {
            border: 0;
        }
        &.active,
        &.active:hover,
        &.range.active,
        &.range.active:hover,
        &.active.disabled,
        &.active.disabled:hover {
            color: #fff !important;

            background-color: $brand-primary !important;
        }
        &.range,
        &.range:hover,
        &.range.disabled,
        &.range.disabled:hover {
            color: #fff !important;

            background-color: lighten($brand-primary, 15%) !important;
        }
        span {
            &.active,
            &.active:hover,
            &.active.disabled:hover,
            &.active:focus,
            &.active:active,
            &.active.active,
            &.active:hover.active,
            &.active.disabled.active,
            &.active.disabled:hover.active {
                border: 0;
                -webkit-border-radius: 0 !important;
                   -moz-border-radius: 0 !important;
                        border-radius: 0 !important;
                background-color: $brand-primary !important;
            }
        }
    }
}
