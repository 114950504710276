.admin-bar img,
.nav-bar img {
    vertical-align: middle;
}

.update {
    position: fixed;
    top: -50px;
    right: 0;
    left: 0;
    z-index: 99999;

    height: 50px;
    overflow: hidden;

    line-height: 50px;

    background: rgba(30,30,30,0.9);
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);

    transition: top 0.2s, height 0.2s;
}

.update.show {
    top: 0;
}

.update.info {
    background: #1ca0de;
}

.update.warning {
    background: rgba(120,54,54,0.9);
}

.update.extended {
    height: 90px;
}

.update {
    padding: 0 5%;
}

.loader {
    display: block;
    float: left;
    width: 150px;
    height: 12px;
    margin: 0;
    margin-top: 17px;
    padding: 0;
    padding-right: 4px;

    border-radius: 8px;
    background: rgba(0,0,0,0.6);
    box-shadow: 0 1px 0 rgba(255,255,255,0.2);

    list-style: none;
}

.loader div {
    position: relative;
    top: 2px;
    left: 2px;

    display: block;
    width: 100%;
    height: 8px;
    overflow: hidden;

    border-radius: 8px;
    background: rgb(45,134,184);
    box-shadow: inset 0 1px 1px rgba(255,255,255,0.4);
}

.loader div > span {
    display: block;
    height: 8px;
    overflow: hidden;

    background: linear-gradient(to bottom,  rgba(255,255,255,0.2) 0%,rgba(0,0,0,0.2) 100%);
}

.loader div > span:after,
.loader div > span > span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    left: 8px;
    z-index: 1;

    overflow: hidden;

    background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
    );
    background-size: 20px 20px;

    content: '';
    animation: move 2s linear infinite;
}

.loader div > span:after {
    display: none;
    overflow: hidden;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 20px 20px;
    }
}

.update .notification {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    padding: 0 25px;

    text-align: center;
}

.update .notification h1 {
    margin: 0;

    font-size: 14px;
    font-weight: normal;
    line-height: 50px;
    color: #fff;
}

.update .notification h1 i {
    margin-top: -2px;
}

.update .notification span.extended {
    font-size: 15px;
    color: #f2f2f2;
}

.update a.cancel {
    position: absolute;
    top: 0;
    right: 0;

    width: 50px;
    height: 50px;
    padding: 0;

    line-height: 50px;
    color: #fff;
    text-align: center;

    background-color: rgba(0,0,0,0.05);

    cursor: pointer;
}

.update a.cancel:hover {
    background-color: rgba(0,0,0,0.1);
}

.admin-bar img,
.nav-bar img {
    vertical-align: middle;
}

.clear {
    clear: both;
}

.admin-bar a,
.admin-bar a:visited,
.admin-bar a:focus,
.nav-bar a,
.nav-bar a:visited,
.nav-bar a:focus {
    color: #444;
    text-decoration: none;

    outline: 0 !important;
}

.admin-bar a:hover,
.admin-bar a:active,
.nav-bar a:hover,
.nav-bar a.active {
    color: #1ca0de;
}

.admin-bar a:focus,
.nav-bar a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.admin-bar h1,
.nav-bar h1 {
    font-size: 20px;
    font-weight: normal;
}

header.base {
    position: fixed;
    top: 0;
    z-index: 90;

    width: 100%;
    min-width: 768px;

    background-color: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.25);

    transition: top 0.2s ease;
}

button.action-btn,
button.action-btn:focus {
    position: relative;

    display: inline-block;
    height: 30px;
    padding: 0 15px;

    font-size: 14px;
    line-height: 30px;
    color: #666;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;

    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 3px;
    outline: none;
    background-color: #f9f9f9;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.8);

    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

span.action-btn,
a.action-btn,
button.action-btn,
a.tag,
a.group-btn,
button.action-btn:focus {
    position: relative;

    display: inline-block;
    height: 30px;
    padding: 0 15px;

    font-size: 14px;
    line-height: 30px;
    color: #666;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;

    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 3px;

    outline: none;
    background-color: #f9f9f9;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.8);

    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

button.action-btn.block {
    width: 100%;
}

button.action-btn.blue {
    color: #fff;

    background: #1ca0de;
    box-shadow: none;
}
