// Pagination
@mixin pagination-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    > li {
        > a,
        > span {
            padding: $padding-vertical $padding-horizontal;
            font-size: $font-size;
            line-height: $line-height;
        }
        &:first-child {
            > a,
            > span {
                @include border-left-radius($border-radius);
            }
        }
        &:last-child {
            > a,
            > span {
                @include border-right-radius($border-radius);
            }
        }
    }
}

// [converter] extracted from `.list-unstyled` for libsass compatibility
@mixin list-unstyled {
        padding-left: 0;

        list-style: none;
}

// cross-browser input placeholder styling
@mixin placeholder($text-color) {
    &::-webkit-input-placeholder {
        color: $text-color;
    }
    &:-moz-placeholder {
        color: $text-color;
    }
    &::-moz-placeholder {
        color: $text-color;
    }
    &:-ms-input-placeholder {
        color: $text-color;
    }
}
