// CKEditor
// defining classes for align text

.cke_toolbox .cke_toolbar:last-child .cke_toolgroup {
    margin-right: 0 !important;
}

body.cke_editable {
    padding: 8px;
}
.cke_editable p {
    margin: 0;

    font-family: 'Merriweather', Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.8;
}
.cke_editable p + p {
    margin-top: 15px;
}
.alignLeft {
    text-align: left;
}
.alignCenter {
    text-align: center;
}
.alignRight {
    text-align: right;
}
.alignJustify {
    text-align: justify;
}
