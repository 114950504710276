//
// Tables
// --------------------------------------------------

.table {
    .progress {
        width: 100px;
        height: 10px;
        margin: 0;
    }
    tr.disabled td {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: $table-bg-active;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0.8;
        }
    }
}

.table {
    &.top-search-results {
        width: 100%;
        max-width: 100%;
        margin-bottom: $line-height-computed;
        // Cells
        > thead,
        > tbody,
        > tfoot {
            > tr {
                th[scope='row'] {
                    width: 40px;
                }
                > th,
                > td {
                    padding: $table-cell-padding;

                    line-height: $line-height-base;
                    vertical-align: middle;
                    word-break: break-word;

                    border-top: 1px solid $table-border-color;
                    a {
                        color: $text-color;
                        .fa {
                            width: 2em;

                            @include opacity(0);
                        }
                        &:hover {
                            text-decoration: underline;
                            .fa {
                                @include opacity(1);
                            }
                        }
                    }
                }
            }
        }
        // Bottom align for column headings
        > thead > tr > th {
            vertical-align: bottom;

            border-bottom: 2px solid $table-border-color;
        }
        // Remove top border from thead by default
        > caption + thead,
        > colgroup + thead,
        > thead:first-child {
            > tr:first-child {
                > th,
                > td {
                    word-break: normal;

                    border-top: 0;
                }
            }
        }
        // Account for multiple tbody instances
        > tbody + tbody {
            border-top: 2px solid $table-border-color;
        }
        // Nesting
        .table {
            background-color: $body-bg;
        }
    }
}

.table-header {
    padding: 10px 25px;
    border: 1px solid $table-border-color;
    border-bottom: 0;
    h1 {
        float: left;
        width: 200px;
        margin: 0;
        padding: 10px 25px 10px 0;
        font-size: 20px;
        font-weight: 900;
        border-right: 1px solid $table-border-color;
    }
    .search {
        height: 42px;
        i.fa {
            padding: 15px;
            color: $table-font-color;
        }
        a.clear {
            position: absolute;
            right: 0;
        }
        input {
            width: 90%;
            height: 100%;
            border: 0;
            &::placeholder {
                color: $table-font-color;
            }
        }
        .search-bar-container {
            position: absolute;
            right: 125px;
            left: 290px;
            display: inline-block;
            height: 42px;
        }
    }
    #permissions {
        margin-top: 5px;
        .fa {
            padding: 0 8px;
        }
    }
}

table.editable {
    thead {
        &#filters th {
            padding: 0;
            position: relative;
            & > div {
                padding: 8px;
            }
            & > .btn-default {
                border-radius: 0;
                height: 37px;
                border: 0;
                border-left: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 3;
            }
        }
        tr {
            th {
                font-weight: 600;
                color: $table-font-color;
                border-bottom-width: 0;
                background-color: $table-header;
                span {
                    margin-left: 5px;
                    font-weight: 300;
                    &.select2-container {
                        margin-left: 0px;
                        span {
                            margin-left: 0px;
                        }
                    }
                }
                i.fa {
                    color: $table-font-color;
                }
                &.active {
                    background-color: $table-cell-active;
                }
                form {
                    .form-group.select-input {
                        margin: 10px;

                        label {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
    tbody {
        tr {
            td {
                color: $table-font-color;
                &.active {
                    background-color: $table-header;
                }
                .settings {
                    color: $link-light-color;
                }
                &.filter .options {
                    right: 0;
                    left: auto;
                }
            }
            &:hover,
            &:hover .table-input input {
                background-color: $table-header;
                .active {
                    background-color: $table-cell-active;
                }
            }
            &:hover td.filter.active {
                background-color: #fff;
            }
        }
    }
    th .filter,
    td.filter {
        position: relative;
        &:hover {
            cursor: pointer;
        }
        &.active {
            background-color: #fff;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
            &:after {
                position: absolute;
                right: 0;
                bottom: -1px;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 3px;
                background-color: #fff;
                content: '';
            }
            .options-form,
            .options {
                display: block;
            }
        }
        .options,
        .options-form {
            position: absolute;
            top: 37px;
            left: 0;
            z-index: 1;
            display: none;
            width: 250px;
            min-width: 100%;
            background-color: #fff;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
                0 1px 6px rgba(0, 0, 0, 0.2);
        }
        .options {
            .checkbox,
            .radio {
                margin: 0;
                height: 100%;
            }
            label {
                display: block;
                padding: 5px 25px 0 45px;
                height: 100%;
            }
            ul {
                padding: 0;
                margin-bottom: 0;
                text-align: left;
                list-style: none;
                li {
                    margin: 0;
                    padding: 0;
                    height: 30px;
                    a {
                        color: $table-font-color;
                        padding: 5px 25px 0 25px;
                        display: block;
                        height: 100%;
                        &.clear {
                            color: $link-color;
                        }
                    }
                    &:hover {
                        background-color: $table-cell-active;
                    }
                }
            }
        }
    }
    tr.new-input {
        background-color: $table-header;
        input::placeholder {
            color: $table-font-color;
        }
    }
    td.table-input {
        padding: 0;
        color: $text-color;
        &.editing {
            border: 1px double $brand-primary;
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.25);
        }
        input {
            width: 100%;
            height: 36px;
            padding: 8px;
            border: 0;
            background-color: transparent;
        }
    }
}

.listing-with-actions {
    table {
        margin-bottom: 0;

        word-wrap: break-word;

        table-layout: fixed;
    }
    .spinner {
        position: relative;

        height: 50px;
    }
    .sort {
        cursor: pointer;
        &.active {
            color: $brand-primary;
        }
    }
    .popover {
        max-width: none;
        .popover-content {
            white-space: nowrap;
        }
    }
    .table {
        border: 1px solid $gray-light;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;

        border-collapse: separate;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
        th {
            padding: 10px;

            font-size: 13px;
            font-weight: normal;
            color: #7f8fa4;

            border-bottom: 0;
            background: #f8fafc;
            & > i.fa {
                margin-left: 2px;
            }

            &.active-parent {
                .sort {
                    display: inline;
                    &.active {
                        display: none;
                    }
                }
                &.sort-desc {
                    .sort {
                        color: $brand-primary;
                        &.sort-asc {
                            display: none;
                        }
                    }
                }
                &.sort-asc {
                    .sort {
                        color: $brand-primary;
                        &.sort-desc {
                            display: none;
                        }
                    }
                }
            }
            &.sort {
                cursor: pointer;
            }
            .sort-desc {
                display: none;
            }
        }
        td {
            padding: 10px;

            font-size: 13px;

            border-top: 1px solid $gray-light;

            &.transfer-actions > a {
                display: inline-block;
                width: 15px;

                color: #7f8fa4;
                text-align: center;
            }
            &.cell-btn-group {
                padding-top: 4px;
                padding-bottom: 4px;
            }
        }
        th > i.fa,
        td > i.fa {
            font-size: 14px;
        }
    }
    .tooltip-inner {
        text-align: left;
    }
}
