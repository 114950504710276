body {
    position: relative;

    /* The fallback value $navbar-height is needed for the old header,
     * when the unified-header feature is enabled for all portals, this
     * fallback can be removed. */
    padding: var(--headerHeight, $navbar-height) 0;

    background-color: $body-bg;
}

.no-padding {
    padding: 0;
}

a:focus,
span:focus,
input:focus,
select:focus,
button:focus {
    outline: none;
}

.no-selection {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.mt-1 {
    margin-top: 10px;
}

.fa-rm {
    margin-right: 0.25em;
}
.fa-lm {
    margin-left: 0.25em;
}

.fa-flip-vertical {
    -webkit-transform: scale(1, -1);
        -ms-transform: scale(1, -1);
            transform: scale(1, -1);

    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
}

.fa.fa-external-link.form-control-feedback {
    right: 36px;
    z-index: 10;

    width: 36px;
    height: 46px;

    line-height: 48px;
    pointer-events: auto;
    &:focus {
        border: none;
        outline: none;
    }
}

.col-md-0 {
    position: relative;
    z-index: 1;
}

.col-md-0 nav.nav-side {
    position: absolute;
    top: 0;
    left: 15px;

    width: 0;

    border-left: 2px solid #ddd;
}

.col-md-0 .nav-side-content {
    overflow: hidden;
}

.col-md-0 + .col-md-12 {
    padding-left: 47px;
}

.open {
    .fa-chevron-down,
    .fa-caret-down {
        @extend .fa-flip-vertical;
    }
}

.progress-message {
    position: relative;

    margin-top: -24px;

    background-color: $brand-primary;

    @include box-shadow(inset 0 -2px 2px rgba(0,0,0,0.1));
    p {
        height: 24px;
        margin: 0;

        font-size: 12px;
        line-height: 24px;
        color: #fff;
    }
}

.popover-actions {
    display: none;
}

hr {
    margin-top: 10px;
    margin-bottom: 10px;

    border: 0;
    border-top: 1px solid rgba(0,0,0,0.05);
}

.collapsed {
    .fa-chevron-up {
        @extend .fa-flip-vertical;
    }
}

@mixin form-control-focus($color: $input-border-focus) {
    $color-rgba: rgba(red($color), green($color), blue($color), 0.6);
    &:focus {
        border-color: $color;
        outline: 0;

        @include box-shadow(inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px $color-rgba);
    }
}

.row--with-equal-height-columns {
    display: flex;
    align-items: stretch;
}

//
// Page section
// --------------------------------------------------

.page-section + .page-section {
    margin-top: 20px;
}

#spinner {
    min-height: 50px;
    position: relative;
}

.page-header h4 .text-muted {
    font-weight: 200;
}

.cal-tips {
    margin: 1px 0 20px 0;
    padding: 10px;

    font-size: 13px;
    text-align: center;

    background-color: $gray-x-light;
    kbd {
        background-color: $brand-primary;
    }
}
