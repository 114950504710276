.modal-compact-view {
    .modal-title {
        display: inline-block;
    }
    .btn-group {
        margin: -0.7em 0 -0.5em 1.5em;
    }
}

.compact-view {
    width: 100%;
    height: 100%;
    min-height: 500px;
    border: 0;
}

.iframe-container {
    position: absolute;
    top: 56px;

    width: 100%;
    height: 500px;
}

.loader {
    position: relative;

    display: inline-block;
    width: 100%;
    height: 100%;
    min-height: 485px;
    margin: 0 0 2em;
    margin: 0 auto 1em;
    padding: 1em;

    text-align: center;
    vertical-align: top;
}

a.compactview {
    display: block;
}
