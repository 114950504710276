.widget-counter {
    font-size: $font-size-h1;
    line-height: $headings-line-height;
    color: $brand-primary;
}

.widget-header {
    margin-bottom: 20px;

    font-size: $font-size-small;
    color: $gray;
    text-transform: uppercase;
    a {
        color: $gray;
        &:hover {
            color: $link-hover-color;
        }
    }
}

.widget-options {
    display: none;
    a {
        margin-left: 3px;
    }
}

.widget-add {
    display: block;

    font-size: $font-size-large;
    font-weight: 600;
    line-height: 100px;
    text-align: center;

    border-width: 2px;
    border-style: dashed;
}

.widget {
    min-height: 120px;
    padding: $padding-large-vertical $padding-large-horizontal;

    border: 1px solid #ccc;
    border-radius: $border-radius-small;

    @include transition(box-shadow 0.1s ease-in);
    &:hover {
        border-color: $brand-primary;

        @include box-shadow(0px 1px 0px rgba(0,0,0,0.1),0px 0px 6px rgba(0,0,0,0.1));
    }
    &:hover .widget-options {
        display: block;
    }
}
